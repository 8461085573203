const navigation = document.getElementById('navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.getElementById('navigation-dropdown');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
	if (!item.classList.contains('dropdown')) {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	}
});

const toggleDropdown = () => {
	if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
		navigationToggler?.click();
	}
};

$('.navbar-toggler').on('click', function () {
    $(this).css('pointer-events', 'none');
    const self = this;

    if (!$('body').hasClass('menu-opened')) {
        $('body').addClass('menu-opened');
        $('header').addClass('menu-opened');
        $('#navigation').addClass('menu-opened');
        $('#mobile-menu-decor').removeClass('d-none');
        setTimeout(function () {
            $('#navigation-dropdown').css('transition-delay', '.7s');
            $('.mobile-menu-mask').css('display', 'block');
        }, 1000);
    } else {
        $('body').removeClass('menu-opened');
        $('header').removeClass('menu-opened');
        $('#navigation').removeClass('menu-opened');
        $('#mobile-menu-decor').addClass('d-none');
        $('#navigation-dropdown').css('transition-delay', '0s');
        $('.mobile-menu-mask').css('display', 'none');
    }

    setTimeout(function () {
        $(self).css('pointer-events', 'all');
    }, 1100);
});
